<template>
  <v-container>
    <v-card class="pa-2">
      <div v-if="searching">
        <p class="text-center mt-5">
          <v-icon>fas fa-atom fa-spin</v-icon> Carregando informações dos
          produtos ...
        </p>
      </div>
      <v-tabs
        centered
        icons-and-text
        v-if="searchResult && searchResult.data && trueStores"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="store in stores"
          :key="`key-tab-${store.id}`"
          :href="`#tab-store-${store.id}`"
        >
          <v-badge
            color="red"
            v-if="store && store.products && store.products.length > 0"
            :content="store.products.length"
          >
            {{ store.name }}
          </v-badge>
          <span v-else>{{ store.name }}</span>
        </v-tab>
        <v-tab-item
          v-for="store in stores"
          :key="`key-tab-item-${store.id}`"
          :value="`tab-store-${store.id}`"
        >
          <template>
            <v-card>
              <v-card-title>
                Produtos
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="store.searchInTable"
                  append-icon="fas fa-search"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
                <v-btn
                  :disabled="searching"
                  color="error"
                  class="ml-5"
                  @click="syncPricesNow(store)"
                  ><v-icon class="mr-2">fas fa-save</v-icon> Arquivar
                  todos</v-btn
                >
              </v-card-title>
              <table-of-products
                :products="store.products"
                :search="store.searchInTable"
              />
            </v-card>
          </template>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-btn
      @click="fetchSearch()"
      class="mr-3"
      style="mb-3"
      :disabled="searching"
      fixed
      fab
      bottom
      right
      color="warning"
    >
      <v-icon>fas fa-sync</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="800">
      <v-card v-if="selectedProd">
        <v-card-title class="headline" primary-title>
          {{ selectedProd.title }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-textarea
                readonly
                v-model="selectedProd.url"
                id="selectedProdUrl"
                rows="3"
                @click="copyTestingCode('selectedProdUrl')"
              ></v-textarea>
              <v-btn color="success" @click="openUrl(selectedProd.url)"
                ><v-icon class="mr-3">fas fa-share</v-icon>Acessar URL</v-btn
              >
            </v-col>
            <v-col cols="6" style="display: none">
              <img
                style="cursor: pointer;"
                :src="selectedProd.thumbnail"
                width="100%"
                @click="copyTestingCode('selectedProdUrl')"
              />
            </v-col>
            <v-col cols="12">
              <h2>Preço Atual: {{ selectedProd.ProductPrice.price }}</h2>
            </v-col>
            <v-col cols="12">
              <v-sheet color="grey">
                <v-sparkline
                  :value="pricesValues"
                  color="#0b008f"
                  height="50"
                  line-width="1.5"
                  padding="15"
                  label-size="6.5"
                  stroke-linecap="round"
                  smooth
                >
                  <template v-slot:label="item"> R${{ item.value }} </template>
                </v-sparkline>
              </v-sheet>
              <v-data-table
                v-if="prices && !loadingPrices"
                :headers="priceHeaders"
                :items="prices"
                :items-per-page="5"
                class="elevation-1"
              >
                <template v-slot:item.updated_at="{ item }">
                  <span>{{ item.updated_at | moment('D/MM/YYYY HH:mm') }}</span>
                </template>
                <template v-slot:item.perc_desc="{ item }">
                  {{ Math.floor(item.perc_desc) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TableOfProductsVue from '../components/TableOfProducts'
// @ is an alias to /src
export default {
  name: 'PriceNews',
  components: {
    'table-of-products': TableOfProductsVue
  },
  data() {
    return {
      selectedProd: null,
      dialog: false,
      search: null,
      ASIN: null,
      limit: 10,
      prime: false,
      searchInTable: null,
      searchResult: null,
      searching: false,
      loadingPrices: false,
      trueStores: [],
      headers: [
        { text: 'ID', value: 'asin' },
        { text: '', value: 'thumbnail' },
        { text: 'Nome', value: 'title' },
        { text: 'Preço', value: 'ProductPrice.price' },
        { text: 'Preço anterior', value: 'ProductPrice.last_price' },
        { text: '%', value: 'ProductPrice.perc_desc' },
        { text: 'Ações', value: 'actions' }
      ],
      priceHeaders: [
        { text: 'Data de atualização', value: 'updated_at' },
        { text: 'Preço', value: 'price' },
        { text: 'Des %', value: 'perc_desc' }
      ],
      prices: []
    }
  },
  mounted() {},
  created() {
    this.fetchTrueStores().then(() => {
      this.fetchSearch()
    })
  },
  methods: {
    copyTestingCode(_elementID) {
      var vm = this
      let testingCodeToCopy = document.querySelector(`#${_elementID}`)
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        if (document.execCommand('copy')) {
          vm.$snotify.success(`Texto copiado para a área de transferência`)
        } else {
          throw new Error(
            'Não foi possível copiar o texto para área de transferência. Copie manualmente'
          )
        }
      } catch (_err) {
        vm.$snotify.error(`Oops... ${_err.message}`)
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    fetchTrueStores() {
      var vm = this
      vm.searching = true
      return new Promise((resolve, reject) => {
        vm.$API
          .Request('post', `stores/list`)
          .then(result => {
            if (result && result.data) {
              vm.trueStores = result.data
            }
            resolve(true)
          })
          .catch(error => {
            console.debug(error)
            vm.$snotify.error(error.message)
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              if (error.response.data.code == 200) {
                vm.$snotify.success(error.response.data.message)
              } else {
                vm.$snotify.error(error.response.data.message)
              }
            }
            reject(error)
          })
      })
    },
    fetchSearch() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `products/priceNews`)
        .then(result => {
          console.debug(result)
          vm.searchResult = result
          vm.searching = false
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    syncPricesNow(store) {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `user/syncPricesUserStore/${store.id}`)
        .then(result => {
          console.debug(result)
          vm.searching = false
          vm.$snotify.success('Atualizado com sucesso!')
        })
        .catch(error => {
          vm.searching = false
          console.debug(error)
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    openUrl(_url) {
      window.open(_url, '_blank')
    },
    openItem(_i) {
      var vm = this
      vm.selectedProd = _i
      vm.loadingPrices = true
      vm.dialog = true
      vm.$API
        .Request('post', `prices/list`, { product_id: vm.selectedProd.id })
        .then(result => {
          vm.loadingPrices = false
          if (result && result.data) {
            vm.prices = result.data
          }
        })
        .catch(error => {
          vm.loadingPrices = false
          console.debug(error)
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    }
  },
  computed: {
    pricesValues: function() {
      var vm = this
      let _values = []
      if (vm.prices && vm.prices.length > 0) {
        vm.prices.forEach(price => {
          _values.push(parseFloat(price.price))
        })
      }
      return _values
    },
    stores: function() {
      console.log('CALCULATED')
      var vm = this
      let _storeProducts = {}
      let _stores = vm.trueStores ? vm.trueStores : []
      let _result = []
      let _emitCount = 0
      if (
        vm.searchResult &&
        vm.searchResult.data &&
        vm.searchResult.data.length > 0 &&
        _stores.length > 0
      ) {
        vm.searchResult.data.forEach(product => {
          if (!_storeProducts[product.store_id]) {
            _storeProducts[product.store_id] = []
          }
          _emitCount += 1
          _storeProducts[product.store_id].push(product)
        })
        _stores.forEach(store => {
          store.products = []
          if (_storeProducts[store.id]) {
            store.products = _storeProducts[store.id]
          }
          _result.push(store)
        })
      }
      vm.$emit('news', { route: 'pricenews', value: _emitCount })
      return _result
    }
  }
}
</script>
